// import
@import "../../assets/scss/helpers/";

// pages
.naver {
  display: flex;
  align-items: center;
  gap: 12px;

  .icon {
    width: 24px;
    height: 24px;
  }

  .button {
    display: inline-flex;
    align-items: center;
    padding: 4px 10px 4px 5px;
    border: 1px solid $gray;
    border-radius: 4px;
    color: $deepgray;
    font-size: 12px;
    font-weight: 500;
    gap: 4px;
    transition: all 0.2s;

    &:hover {
      border-color: $green;
      color: $green;
    }
  }
}
