@use "sass:math";

// 화면 채우기
@mixin fullscreen($z: 1, $p: "absolute") {
  position: #{$p};
  z-index: $z;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// 요소 띄우기
@mixin absolute($p: "center") {
  @if #{$p} == "center" {
    // 중앙
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  } @else if #{$p} == "vertical" {
    // 세로
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
  } @else if #{$p} == "horizontal" {
    // 가로
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
}

// 선택 차단
@mixin disable($boolean: "true") {
  @if $boolean == "true" {
    pointer-events: none;
    touch-action: none;
    user-select: none;
  } @else {
    pointer-events: auto;
    touch-action: auto;
    user-select: auto;
  }
}

// 한 줄 말줄임
@mixin text-ellipsis() {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 여러줄 말줄임
@mixin box-ellipsis($line: 3, $lineHeight: 20px, $boxHeight: "auto") {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  line-height: $lineHeight;
  text-align: left;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
  @if ($boxHeight) == "fixed" {
    height: $lineHeight * $line;
  } @else {
    height: auto;
    max-height: $lineHeight * $line;
  }
}
