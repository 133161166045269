// fonts
@import "./fonts";

// helpers
@import "./helpers";

// base
@import "./base/reset";
@import "./base/typography";
@import "./base/colors";

// pages
body {
  color: $black;
  font-family: "MaruBuri", "마루 부리OTF", sans-serif;
  font-size: 16px;
  letter-spacing: 0.02em;
  overflow-wrap: break-word;
  word-break: keep-all;
}

.container {
  width: 100%;
  max-width: calc($container + ($gutter * 2));
  padding: 0 $gutter;
  margin: 0 auto;
}

.common-headline {
  position: relative;
  display: inline-flex;
  max-width: fit-content;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  color: $green;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  @media ($md_down) {
    font-size: 18px;
  }

  &::after {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-image: url("../svgs/icon-star.svg");
    background-repeat: no-repeat;
    background-size: 8px 8px;
    content: "";
  }
}

.br-pc {
  display: block;

  @media ($md_down) {
    display: none;
  }
}

.br-mo {
  display: none;

  @media ($md_down) {
    display: block;
  }
}
