// import
@import "../../assets/scss/helpers";

// pages
#instructor {
  padding: 100px 0;

  @media ($md_down) {
    padding: 50px 0;
  }

  .sec-header {
    :global(.container) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .sec-instructor {
    margin-top: 60px;

    @media ($md_down) {
      margin-top: 20px;
    }

    .instructor-list {
      max-width: 700px;
      margin: 0 auto;

      > li {
        position: relative;
        display: grid;
        gap: 32px;
        grid-template: repeat(3, min-content) / 300px 1fr;

        @media ($md_down) {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
        }

        &:not(:last-child) {
          padding-bottom: 60px;
          margin-bottom: 60px;

          @media ($md_down) {
            padding-bottom: 30px;
            margin-bottom: 30px;
          }

          &::after {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            width: 80px;
            height: 1px;
            margin: 0 auto;
            background-color: $yellow;
            content: "";
          }
        }
      }
    }
  }
}
