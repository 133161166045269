// import
@import "../../assets/scss/helpers";

// component
.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $green;
  font-size: 16px;
  font-weight: 600;
  grid-column: span 2;

  small {
    font-size: 12px;
  }
}

.photo {
  width: 100%;
  height: 100%;

  @media ($md_down) {
    width: initial;
    margin: 0 -16px;
  }

  .inner {
    position: relative;
  }

  .image {
    background-color: $lightgray;
    object-fit: cover;
    object-position: center;
  }

  .certification {
    position: absolute;
    right: 10px;
    bottom: 10px;

    width: 60px;
    height: 60px;
  }
}

.introduce {
  @media ($md_down) {
    width: 100%;
    max-width: 300px;
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media ($md_down) {
      gap: 16px;
    }

    > li {
      display: flex;
      flex-direction: column;
      gap: 8px;

      strong {
        color: $green;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  ul {
    > li {
      font-size: 12px;
    }
  }
}

.message {
  margin-top: 28px;
  font-size: 12px;
  grid-column: span 2;
  text-align: center;

  @media ($md_down) {
    margin-top: 14px;
  }
}
