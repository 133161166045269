// import
@import "../../assets/scss/helpers/";

// pages
#home {
  padding: 100px 0;

  @media ($md_down) {
    padding: 0 0 50px;
  }

  :global(.container) {
    display: flex;
    flex-direction: column;
  }

  .hero {
    width: 960px;
    height: 500px;
    background-color: $lightgray;
    object-fit: cover;
    object-position: center;

    @media ($md_down) {
      width: initial;
      max-width: initial;
      height: 300px;
      margin: 0 -16px;
    }
  }

  .textbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    text-align: center;

    @media ($md_down) {
      margin-top: 50px;
    }

    .text {
      font-size: 20px;
      font-weight: 400;

      @media ($md_down) {
        font-size: 18px;
      }
    }

    :global(.common-headline) {
      color: $black;
    }

    .subtext {
      padding-top: 22px;
      color: $green;
      font-size: 16px;
      font-weight: 600;

      @media ($md_down) {
        font-size: 14px;
      }
    }
  }

  .instagram {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
    gap: 20px;

    @media ($sm_down) {
      margin-top: 80px;
    }

    .label {
      display: inline-flex;
      align-items: center;
      padding: 4px 10px;
      border-radius: 10px 10px 0 10px;
      background-color: rgba($white, 0.8);
      font-size: 14px;
      font-weight: bold;
      gap: 8px;
      letter-spacing: normal;

      img {
        width: 20px;
        height: 20px;
      }
    }

    ul {
      display: grid;
      width: 100%;
      gap: 8px;
      grid-template: repeat(2, 1fr) / repeat(4, 1fr);

      @media ($sm_down) {
        grid-template: repeat(4, 1fr) / repeat(2, 1fr);
      }
    }

    li {
      aspect-ratio: 1 / 1;
      background-color: $lightgray;

      &:first-child {
        aspect-ratio: auto;
        grid-row: span 2;
      }

      a {
        position: relative;
        display: block;
        height: 100%;

        &:hover,
        &:focus {
          .caption {
            opacity: 1;
            visibility: visible;

            span {
              transform: translate3d(0, 0, 0);
            }
          }
        }
      }

      .caption {
        @include fullscreen();
        overflow: hidden;
        padding: 24px;
        background-color: rgba($black, 0.9);
        color: $white;
        font-size: 12px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.6s, visibility 0.6s;
        visibility: hidden;

        @media ($md_down) {
          display: none;
        }

        span {
          @include box-ellipsis($line: 6);
          transform: translate3d(0, 30px, 0);
          transition: transform 0.6s;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}
