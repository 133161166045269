// import
@import "../../assets/scss/helpers";

// component
.banner {
  position: sticky;
  z-index: 10;
  bottom: 0;
  width: 100%;
  background-color: $white;
  letter-spacing: normal;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  .button {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 100%;
    width: 36px;
    height: 36px;
    background: $black;
    color: $white;

    @media ($md_down) {
      width: 30px;
      height: 30px;
    }

    svg {
      width: 20px;
      height: 20px;
      color: inherit;
    }
  }
}

.card {
  display: flex;
  min-height: 140px;
  align-items: center;
  padding: 24px 0;
  background-color: $white;

  a {
    display: grid;
    align-content: center;
    align-items: center;
    gap: 8px 20px;
    grid-template: repeat(2, min-content) / 100px 1fr;
    grid-template-areas:
      "logo label"
      "logo text";

    @media ($md_down) {
      column-gap: 15px;
      grid-template-columns: 50px 1fr;
    }
  }

  .logo {
    grid-area: logo;
    text-align: center;

    img {
      width: 100%;
      max-width: 80px;
    }
  }

  .label {
    align-self: flex-end;
    grid-area: label;
    line-height: 1;

    @media ($sm_down) {
      align-self: flex-start;
    }

    span {
      display: inline-flex;
      padding: 0 12px;
      border-radius: 10px 10px 0 10px;
      background-color: $white;
      color: $green;
      font-size: 12px;
      font-weight: bold;
      line-height: 180%;
      vertical-align: middle;
    }
  }

  .text {
    align-self: flex-start;
    font-size: 12px;
    font-weight: 600;
    grid-area: text;
    line-height: 180%;

    strong {
      color: inherit;
      text-decoration: underline;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

// LLLCard
.lllcard {
  background-color: #94d0c9;

  .text {
    strong {
      color: $green;
    }
  }
}

// Review
.review {
  background-color: $green;

  .logo {
    @media ($sm_down) {
      img {
        max-width: 40px;
      }
    }
  }

  .label {
    span {
      background-color: $yellow;
      color: $green;
    }
  }

  .text {
    color: $white;

    strong {
      color: inherit;
    }
  }
}
