// import
@import '../../assets/scss/helpers';

// pages
#about {
  padding: 100px 0;

  @media ($md_down) {
    padding: 50px 0;
  }

  .sec-header {
    :global(.container) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 60px;

      @media ($md_down) {
        gap: 20px;
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      gap: 30px;
      text-align: center;

      @media ($md_down) {
        gap: 16px;
      }

      p:first-child {
        color: $green;
        font-weight: 600;
      }
    }
  }

  .sec-process {
    padding: 120px 0;
    margin-top: 120px;
    background-color: rgba($green, 0.04);

    @media ($md_down) {
      padding: 50px 0;
      margin-top: 50px;
    }

    :global(.container) {
      display: flex;
      max-width: calc(1440px + ($gutter * 2));
      flex-direction: column;
      align-items: center;
      gap: 60px;
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 60px;
      text-align: center;

      @media ($md_down) {
        gap: 20px;
      }

      .description {
        font-size: 14px;
      }
    }

    .process {
      display: flex;
      gap: 60px;
      text-align: center;

      @media ($xl_down) {
        display: grid;
        grid-template: repeat(2, min-content) / repeat(6, 1fr);
      }

      @media ($md_down) {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }

      li {
        display: flex;
        flex: 1 1 0;
        flex-direction: column;
        gap: 8px;

        @media ($xl_down) {
          grid-column: span 2;

          &:nth-child(4),
          &:nth-child(5) {
            grid-column: span 3;
          }
        }

        strong {
          color: $green;
          font-size: 16px;
          font-weight: 600;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }

  .sec-center {
    margin-top: 120px;

    @media ($md_down) {
      margin-top: 50px;
    }

    :global(.container) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 60px;

      @media ($md_down) {
        gap: 20px;
      }
    }

    ul {
      display: grid;
      gap: 8px;
      grid-template: repeat(2, min-content) / repeat(3, 1fr);

      @media ($md_down) {
        grid-template: repeat(2, min-content) / repeat(2, 1fr);
      }

      li {
        position: relative;

        &:first-child,
        &:last-child {
          grid-column: span 2;
        }

        img {
          width: 100%;
          height: 400px;
          object-fit: cover;
          object-position: center;

          @media ($md_down) {
            height: 350px;
          }

          @media ($sm_down) {
            height: 200px;
          }
        }

        .label {
          position: absolute;
          right: 16px;
          bottom: 16px;
          display: inline-flex;
          align-items: center;
          padding: 4px 10px;
          border-radius: 10px 10px 0 10px;
          background-color: rgba($white, 0.8);
          font-size: 10px;
          font-weight: bold;
          gap: 4px;
          letter-spacing: normal;

          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }
}
