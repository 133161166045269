// Colors
$background: #FEFAF6;
$green:      #275048;
$yellow:     #FABA20;
$white:      #FFFFFF;
$black:      #313131;
$deepgray:   #555555;
$gray:       #999999;
$lightgray:  #f1f1f1;

$theme-colors: (
  "background": $background,
  "green":      $green,
  "yellow":     $yellow,
  "white":      $white,
  "black":      $black,
  "deepgray":   $deepgray,
  "gray":       $gray,
);

// Layouts
$container: 960px;
$gutter: 16px;


// breakpoints
// https://getbootstrap.com/docs/5.3/layout/breakpoints/#available-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$xxl_up: 'min-width: #{map.get($grid-breakpoints, 'xxl')}';
$xl_up:  'min-width: #{map.get($grid-breakpoints, 'xl')}';
$lg_up:  'min-width: #{map.get($grid-breakpoints, 'lg')}';
$md_up:  'min-width: #{map.get($grid-breakpoints, 'md')}';
$sm_up:  'max-width: #{map.get($grid-breakpoints, 'sm')}';
$xs_up:  'max-width: #{map.get($grid-breakpoints, 'xs')}';

$xxl_down: 'max-width: #{map.get($grid-breakpoints, 'xxl') - 1}';
$xl_down:  'max-width: #{map.get($grid-breakpoints, 'xl') - 1}';
$lg_down:  'max-width: #{map.get($grid-breakpoints, 'lg') - 1}';
$md_down:  'max-width: #{map.get($grid-breakpoints, 'md') - 1}';
$sm_down:  'max-width: #{map.get($grid-breakpoints, 'sm') - 1}';
