// import
@import '../../assets/scss/helpers/';

// pages
#footer {
  ul {
    display: flex;
    flex-direction: column;
    padding: 32px 0 96px;
    border-top: 1px solid $green;
    gap: 4px;
  }

  li {
    font-size: 12px;

    .title {
      color: $green;
      font-size: inherit;
      font-weight: 600;
    }

    &.contact {
      padding-top: 12px;
      line-height: 1;

      a {
        display: inline-flex;
        align-items: center;
        gap: 2px;
        line-height: 0;
        text-transform: uppercase;
        vertical-align: middle;
      }
    }

    &.copyright {
      padding-top: 16px;
      color: $deepgray;
      font-size: 10px;
    }
  }
}
