// import
@import "../../assets/scss/helpers";

// pages
#contact {
  padding: 100px 0;

  @media ($md_down) {
    padding: 50px 0;
  }

  .sec-header {
    :global(.container) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .sec-content {
    margin-top: 60px;

    @media ($md_down) {
      margin-top: 30px;
    }

    :global(.container) {
      display: grid;
      column-gap: 16px;
      grid-template: repeat(2, min-content) / repeat(2, 1fr);
      row-gap: 60px;

      @media ($md_down) {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
      }
    }

    .block-map {
      grid-column: span 2;

      @media ($md_down) {
        margin: 0 -16px;
      }

      a {
        display: block;
        height: 490px;
        background-color: $lightgray;

        @media ($md_down) {
          height: 250px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .block-location {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .title {
        color: $green;
        font-size: 16px;
        font-weight: 600;
      }

      .description {
        display: flex;
        flex-direction: column;
        gap: 6px;

        p {
          font-size: 14px;
        }

        p:last-child {
          font-size: 12px;
        }
      }
    }

    .block-contact {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      gap: 12px;

      .title {
        color: $green;
        font-size: 16px;
        font-weight: 600;
      }

      .sns {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;

        a {
          display: flex;
          align-items: center;
          line-height: 1;
        }

        span {
          display: block;
          width: 25px;

          img {
            width: 18px;
            height: 18px;
          }
        }
      }

      .contact {
        display: flex;
        flex-direction: column;
        gap: 6px;

        a {
          display: inline-flex;
        }

        span {
          display: block;
          width: 25px;
          font-weight: 700;
        }
      }
    }
  }
}
