// import
@import "../../assets/scss/helpers";

// pages
#classes {
  padding: 100px 0;

  @media ($md_down) {
    padding: 50px 0;
  }

  .sec-header {
    :global(.container) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .sec-classes {
    margin-top: 60px;

    @media ($md_down) {
      margin-top: 20px;
    }

    .tab-buttons {
      background-color: rgba($green, 0.04);

      @media ($md_down) {
        :global(.container) {
          // padding: 0;
        }
      }

      ul {
        display: flex;
        gap: 60px;

        @media ($md_down) {
          gap: 0;
        }
      }

      li {
        flex: 1 1 0;

        a {
          display: block;
          padding: 40px 0;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: inherit;
          text-align: center;

          @media ($md_down) {
            padding: 16px 4px;
            font-size: 12px;
          }

          span {
            display: inline-block;
            padding: 0 5px;
            border-bottom: 1px solid transparent;
          }

          &.active {
            background-color: $white;
            color: $green;

            span {
              border-color: $yellow;
            }
          }
        }
      }
    }
  }

  .private {
    padding-top: 60px;

    @media ($md_down) {
      padding-top: 40px;
    }

    :global(.container) {
      display: grid;
      gap: 60px 32px;
      grid-template: repeat(3, min-content) / repeat(2, 1fr);

      @media ($md_down) {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
    }

    .message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      gap: 60px;
      grid-column: span 2;
      text-align: center;

      @media ($md_down) {
        gap: 40px;
      }

      strong {
        color: $green;
        font-weight: 600;
      }

      small {
        font-size: 12px;
      }
    }

    .photos {
      display: grid;
      gap: 8px;
      grid-column: span 2;
      grid-template: min-content / repeat(5, 1fr);

      @media ($md_down) {
        display: flex;
        overflow: auto hidden;
        scroll-snap-type: x mandatory;
      }

      img {
        width: 100%;
        height: 100%;
        padding: 4px;
        border: 1px solid $lightgray;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        object-position: top;

        @media ($md_down) {
          width: 35%;
          height: 35%;
          scroll-snap-align: center;
        }
      }
    }

    .classes {
      display: flex;
      flex: 1 1 0;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      @media ($md_down) {
        gap: 20px;
      }

      .list {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 32px;

        @media ($md_down) {
          gap: 16px;
        }

        li {
          display: flex;
          flex-direction: column;
          padding: 30px;
          background-color: rgba($green, 0.04);
          gap: 8px;

          @media ($md_down) {
            padding: 20px;
          }

          strong {
            color: $green;
            font-size: 14px;
            font-weight: 600;
          }

          p {
            font-size: 12px;
          }
        }
      }
    }
  }

  .group {
    padding-top: 60px;

    @media ($md_down) {
      padding-top: 40px;
    }

    :global(.container) {
      display: flex;
      flex-direction: column;
      gap: 60px;

      @media ($md_down) {
        gap: 40px;
      }
    }

    .message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      gap: 60px;
      grid-column: span 2;
      text-align: center;

      @media ($md_down) {
        gap: 40px;
      }

      strong {
        color: $green;
        font-weight: 600;
      }

      small {
        font-size: 12px;
      }
    }

    .photos {
      display: grid;
      gap: 8px;
      grid-template: min-content / repeat(5, 1fr);

      @media ($md_down) {
        display: flex;
        overflow: auto hidden;
        scroll-snap-type: x mandatory;
      }

      img {
        width: 100%;
        height: 100%;
        padding: 4px;
        border: 1px solid $lightgray;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        object-position: top;

        @media ($md_down) {
          width: 35%;
          height: 35%;
          scroll-snap-align: center;
        }
      }
    }

    .classes {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      @media ($md_down) {
        gap: 20px;
      }

      .list {
        display: grid;
        width: 100%;
        gap: 32px;
        grid-template: repeat(3, 1fr) / repeat(2, 1fr);

        @media ($md_down) {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        li {
          display: flex;
          flex-direction: column;
          padding: 30px;
          background-color: rgba($green, 0.04);
          gap: 8px;

          @media ($md_down) {
            padding: 20px;
          }

          strong {
            color: $green;
            font-size: 14px;
            font-weight: 600;
          }

          p {
            font-size: 12px;
          }
        }
      }
    }
  }

  .couple {
    padding-top: 60px;

    @media ($md_down) {
      padding-top: 40px;
    }

    :global(.container) {
      display: flex;
      flex-direction: column;
      gap: 60px;

      @media ($md_down) {
        gap: 40px;
      }
    }

    .message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      gap: 60px;
      grid-column: span 2;
      text-align: center;

      @media ($md_down) {
        gap: 40px;
      }

      strong {
        color: $green;
        font-weight: 600;
      }

      small {
        font-size: 12px;
      }
    }

    .photos {
      display: grid;
      gap: 8px;
      grid-template: min-content / repeat(5, 1fr);

      @media ($md_down) {
        display: flex;
        overflow: auto hidden;
        scroll-snap-type: x mandatory;
      }

      img {
        width: 100%;
        height: 100%;
        padding: 4px;
        border: 1px solid $lightgray;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        object-position: top;

        @media ($md_down) {
          width: 35%;
          height: 35%;
          scroll-snap-align: center;
        }
      }
    }

    .classes {
      display: flex;
      flex-direction: column;
      align-items: center;

      .list {
        display: flex;
        width: 100%;
        max-width: 500px;
        flex-direction: column;
        margin-top: 24px;
        gap: 9px;

        li {
          padding: 20px;
          background-color: rgba($green, 0.04);
          font-size: 12px;
          text-align: center;
        }
      }

      .description {
        margin-top: 60px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;

        @media ($md_down) {
          margin-top: 40px;
        }
      }
    }
  }
}
