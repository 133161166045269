// import
@import "../../assets/scss/helpers/";

// pages
#header {
  position: relative;
  z-index: 10;

  @media ($md_down) {
    border-bottom: 1px solid rgba($green, 0.2);
  }

  :global(.container) {
    display: flex;
    height: 240px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 36px;

    @media ($md_down) {
      height: 80px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: $white;
    }
  }

  .title {
    color: $green;

    .mo {
      display: none;
    }

    .pc {
      display: block;
    }

    svg {
      width: 46px;
      height: 64px;
    }

    @media ($md_down) {
      .mo {
        display: block;
      }

      .pc {
        display: none;
      }

      svg {
        width: 95px;
        height: 40px;
      }
    }
  }

  .toggle-gnb {
    display: none;
    height: 40px;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .label {
      color: $black;
      font-size: 10px;
      font-weight: bold;
      opacity: 1;
      text-transform: uppercase;
      transition: opacity 0.2s;

      :global(html.is-active-gnb) & {
        opacity: 0;
      }
    }

    .menu {
      display: block;
      height: 24px;

      :global(html.is-active-gnb) & {
        display: none;
      }
    }

    .close {
      display: none;
      height: 24px;

      :global(html.is-active-gnb) & {
        display: block;
      }
    }

    @media ($md_down) {
      display: flex;
    }
  }

  .gnb {
    @media ($md_down) {
      position: absolute;
      z-index: -1;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 16px;
      background-color: $white;
      box-shadow: 0 0 10px rgba($green, 0.4);
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.5s, opacity 0.3s;

      :global(html.is-active-gnb) & {
        opacity: 1;
        transform: scaleY(1);
      }
    }

    ul {
      display: flex;
      justify-content: center;
      gap: 56px;

      @media ($md_down) {
        flex-direction: column;
        gap: 16px;
      }
    }

    li {
      a {
        display: block;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.04em;
        text-transform: uppercase;

        @media ($md_down) {
          display: block;
        }

        span {
          display: inline-block;
          border-bottom: 1px solid transparent;
        }

        @media ($md_down) {
          font-size: 14px;
        }

        &.active {
          color: $green;

          span {
            border-bottom-color: $yellow;
          }
        }
      }
    }
  }
}
